import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Markdown from './Markdown';

const useStyles = makeStyles(theme => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
    color: "black",
  },
}));

async function fetchPost(classes, postsUrl) {
  let posts = [];
  for (let i = 0; i < postsUrl.length; ++i) {
    const each = postsUrl[i];
    const post = await fetch(each).then(response => response.text()).catch(e => console.log(e))
    posts.push(post);
  }
  let postsElement = posts.map(post => (
    <Markdown className={classes.markdown} key={post.substring(0, 40)}>
      {post}
    </Markdown>))

  ReactDOM.render(
    postsElement,
    document.getElementById("postContainer")
  )
}

export default function Main(props) {
  const classes = useStyles();
  const { postsUrl, title } = props;
  fetchPost(classes, postsUrl);

  return (
    <Grid item xs={12} md={8}>
      <Typography variant="h6" gutterBottom style={{ color: "black" }}>
        {title}
      </Typography>
      <Divider />
      <div id="postContainer">

      </div>
    </Grid>
  );
}

Main.propTypes = {
  postsUrl: PropTypes.array,
  title: PropTypes.string,
};
