import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import MainFeaturedPost from "./Sections/MainFeaturedPost";
import FeaturedPost from "./Sections/FeaturedPost";
import Main from "./Sections/Main";
import Sidebar from "./Sections/Sidebar";
import post1 from './Sections/blog-post.1.md';
import post2 from './Sections/blog-post.2.md';
import post3 from './Sections/blog-post.3.md';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const mainFeaturedPost = {
  title: 'Title of a longer featured post',
  description:
    "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
  image: require('assets/img/bg.jpg'),
  imgText: 'main image description',
  linkText: 'Continue reading…',
};

const featuredPosts = [
  {
    title: 'Featured post',
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: require('assets/img/bg2.jpg'),
    imageText: 'Image Text',
  },
  {
    title: 'Post title',
    date: 'Nov 11',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: require('assets/img/bg3.jpg'),
    imageText: 'Image Text',
  },
];

const posts = [post1, post2, post3];

const sidebar = {
  title: 'About',
  description:
    'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  // social: [
  //   { name: 'GitHub', icon: GitHubIcon },
  //   { name: 'Twitter', icon: TwitterIcon },
  //   { name: 'Facebook', icon: FacebookIcon },
  // ],
};

export default function Blog(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Night12138's Page"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Night12138's Page!</h1>
              <h4>
                Welcome to my page!
              </h4>
              <br />
              <Button
                color="muted"
                size="lg"
                href="#"
                rel="noopener noreferrer"
                title="Coming soon!"
              >
                <i className="fas fa-play" />
                Say hello to me?
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <MainFeaturedPost post={mainFeaturedPost} />
        <div className={classes.container}>
          <GridContainer spacing={2}>
            {featuredPosts.map(post => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </GridContainer>
          <GridContainer spacing={5}>
            <Main title="From the firehose" postsUrl={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
            // social={sidebar.social}
            />
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
